import moment from "moment"
import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import { GearModel } from "../models/GearModel"
import RichText from "../components/typography/RichText"
import GearTable from "../components/tables/GearTable"
import SmallContainer from "../components/layout/SmallContainer"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: GearModel
  tags: string[]
  publicationDate: string
}

const GearTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.title[0].text}`}
        description={`${pageContext.data.subtitle[0].text}`}
        thumbnailUrl={pageContext.data.main_image.url}
        imageAlt={`${pageContext.data.main_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className="mb-8">
        <WideContainer center={true}>
          <div className={`container mb-6`}>
            <p className="mb-4 mt-8 text-xs uppercase font-bold">
              {moment(pageContext.publicationDate).format("MMMM Do YYYY")} •
              Gear Guide • Comparison
            </p>
            <h1 className="mt-0 mb-4">{pageContext.data.title[0].text}</h1>
            <h4 className="mt-0 mb-4 text-grey">
              {pageContext.data.subtitle[0].text}
            </h4>
          </div>
        </WideContainer>
        <SmallContainer center={true}>
          <RichText text={pageContext.data.description} />
        </SmallContainer>
        <WideContainer center={true} className="mb-2 article">
          <GearTable
            gearList={pageContext.data.gear_list}
            gearColumnList={pageContext.data.gear_list_columns}
          />
        </WideContainer>
        <SmallContainer center={true}>
          <RichText text={pageContext.data.last_description} />
        </SmallContainer>
      </div>
    </Layout>
  )
}

export default GearTemplate
