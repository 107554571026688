import React from "react"
import { useSortableData } from "../../hooks/TableSortHook"
import { GearItem, GearColumn } from "../../models/GearModel"
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa"
import Variables from "../../constants/Variables"

interface Props {
  gearColumnList?: GearColumn[]
  gearList?: GearItem[]
}

export default function GearTable({ gearColumnList, gearList }: Props) {
  const { items, requestSort, sortConfig } = useSortableData(gearList)
  const rowPadding = "px-4 py-4"

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8 mb-12">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-white uppercase bg-theme">
          <tr>
            <th scope="col" className="px-4 py-3">
              Gear Name
            </th>
            <th
              scope="col"
              className="px-4 py-3 cursor-pointer"
              onClick={() => requestSort("gear_weight")}
            >
              Weight
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "descending" &&
                sortConfig.key === "gear_weight" && (
                  <FaArrowCircleUp
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "ascending" &&
                sortConfig.key === "gear_weight" && (
                  <FaArrowCircleDown
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
            </th>
            <th
              scope="col"
              className="px-4 py-3 cursor-pointer"
              onClick={() => requestSort("gear_price")}
            >
              Price
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "descending" &&
                sortConfig.key === "gear_price" && (
                  <FaArrowCircleUp
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "ascending" &&
                sortConfig.key === "gear_price" && (
                  <FaArrowCircleDown
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
            </th>
            <th
              scope="col"
              className="px-4 py-3 cursor-pointer"
              onClick={() => requestSort("gear_score")}
            >
              Score
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "descending" &&
                sortConfig.key === "gear_score" && (
                  <FaArrowCircleUp
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
              {sortConfig &&
                sortConfig.direction &&
                sortConfig.direction === "ascending" &&
                sortConfig.key === "gear_score" && (
                  <FaArrowCircleDown
                    size={14}
                    className="inline-block"
                    style={{ marginBottom: 0, marginRight: 5, marginLeft: 10 }}
                  />
                )}
            </th>
            {gearColumnList.map((item, index) => {
              return (
                <th key={index.toString()} scope="col" className="px-4 py-3">
                  {item.column_title[0].text}
                </th>
              )
            })}
            <th scope="col" className="px-4 py-3">
              Link
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr
                key={index.toString()}
                className="odd:bg-white even:bg-lightest"
              >
                <th
                  scope="row"
                  className={rowPadding + ` font-medium whitespace-nowrap`}
                >
                  {item.gear_name[0].text}
                </th>
                <td className={rowPadding}>
                  {item.gear_weight ? item.gear_weight + " g" : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_price ? item.gear_price + " €" : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_score ? item.gear_score : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_number_feature ? item.gear_number_feature : "N/A"}
                </td>
                <td className="px-6 py-4">
                  {item.gear_number_feature_2
                    ? item.gear_number_feature_2
                    : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_feature ? item.gear_feature : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_feature_2 ? item.gear_feature_2 : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_feature_3 ? item.gear_feature_3 : "N/A"}
                </td>
                <td className={rowPadding}>
                  {item.gear_link.url && (
                    <a
                      href={item.gear_link.url}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      Link
                    </a>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const tagStyles = {
  borderWidth: 0,
  borderStyle: "solid",
  fontSize: 12,
} as React.CSSProperties
