import React from "react"
import { Description } from "../../models/NationalPark"

interface Props {
  text: Description[]
}

const RichText: React.FunctionComponent<Props> = ({ text }) => {
  const listItems = text.map((paragraph, index) => (
    <div key={index}>
      {paragraph.type === "heading2" && <h2>{paragraph.text}</h2>}
      {paragraph.type === "heading3" && <h3>{paragraph.text}</h3>}
      {paragraph.type === "heading4" && <h4>{paragraph.text}</h4>}
      {paragraph.type === "heading5" && <h5>{paragraph.text}</h5>}
      {paragraph.type === "heading6" && <h6>{paragraph.text}</h6>}
      {paragraph.type === "paragraph" && <p>{paragraph.text}</p>}
      {paragraph.type === "image" && (
        <img src={paragraph.url} alt={paragraph.alt} />
      )}
      {paragraph.type === "list-item" && <li>{paragraph.text}</li>}
    </div>
  ))

  return <div>{listItems}</div>
}

export default RichText
